import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ThemeContext from "../../context/ThemeContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneSquare,
  faSquareEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const GrievancePage = () => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout>
          <Seo title="Techimpace Best Digital Agency for Website Designing, Web Development and Software Development" />
          <section className={theme.dark ? " dark dark-5 " : " light "}>
            <div className="container">
              <div className="row">
                <div className="row">
                  <div className="col-12">
                    <h1>Grievance Cell</h1>
                    <p className="text-primary">You can report.</p>
                    <hr />
                  </div>
                  <div className="col-12">
                    <h4>Please Contact</h4>
                    <p>
                      <FontAwesomeIcon icon={faPhoneSquare} /> 964 133 9361
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faSquareEnvelope} />{" "}
                      grievance@techimpace.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}
export default GrievancePage
